*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Bai Jamjuree, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.bg-100 {
  background: var(--bg-100);
}

.bg-400 {
  background: var(--bg-400);
}

.bg-500 {
  background: var(--bg-500);
}

.bg-600 {
  background-color: var(--bg-600);
}

.bg-700 {
  background-color: var(--bg-700);
}

.bg-800 {
  background-color: var(--bg-800);
}

.bg-900 {
  background: var(--bg-900);
}

.bg-1000 {
  background: var(--bg-1000);
}

.text-300 {
  color: var(--text-300);
}

.text-500 {
  color: var(--text-500);
}

.text-600 {
  color: var(--text-600);
}

.text-700 {
  color: var(--text-700);
}

.text-800 {
  color: var(--text-800);
}

.text-900 {
  color: var(--text-900);
}

::-webkit-scrollbar {
  width: .25rem;
  height: .25rem;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-700);
}

::-webkit-scrollbar-thumb {
  background-color: var(--bg-500);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--bg-300);
}

._focusable-base {
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focusable-field {
  background-color: var(--bg-800);
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focusable-field:hover {
  background-color: var(--bg-700);
}

.focusable-field:active {
  background-color: #fff3;
}

.focusable-field::placeholder {
  color: var(--text-600);
}

.focusable-field {
  border-color: var(--bg-700);
}

.focusable-icon-button, .icon-button {
  cursor: pointer;
  color: var(--text-500);
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focusable-icon-button:hover, .icon-button:hover, .focusable-icon-button:focus, .icon-button:focus {
  color: var(--text-900);
}

.focusable-icon-button.active, .icon-button.active {
  color: var(--text-100);
}

.icon-button.disabled, .icon-button.\!disabled {
  cursor: not-allowed;
}

.btn-primary {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-primary:disabled {
  cursor: not-allowed;
}

.btn-primary {
  background-color: var(--hl-700);
}

.btn-primary:hover:enabled {
  background-color: var(--hl-500);
}

.btn-primary:active:enabled {
  background-color: var(--hl-400);
}

.btn-primary:disabled {
  background-color: var(--hl-900);
  color: var(--text-700);
}

.btn-hollow {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-hollow:disabled {
  cursor: not-allowed;
}

.btn-hollow {
  color: var(--hl-500);
  background-color: #0000;
}

.btn-hollow:hover:enabled {
  background-color: var(--hl-900);
}

.btn-hollow {
  border: 1px solid var(--hl-500);
  border-color: var(--hl-800);
}

.btn-hollow:disabled {
  color: var(--hl-800);
}

.btn-secondary {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-secondary:disabled {
  cursor: not-allowed;
}

.btn-secondary {
  background-color: var(--bg-600);
}

.btn-secondary:hover {
  background-color: var(--bg-500);
}

.btn-secondary:active {
  background-color: var(--bg-400);
}

.btn-secondary:disabled {
  background-color: var(--bg-700);
  color: var(--text-400);
}

.btn-red {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-red:disabled {
  cursor: not-allowed;
}

.btn-red {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.btn-red:active {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

.btn-red:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.btn-red {
  color: var(--black-900);
}

.btn-red:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
  color: var(--text-600);
}

.btn-green {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-green:disabled {
  cursor: not-allowed;
}

.btn-green {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.btn-green:active {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.btn-green:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.btn-green:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
  color: var(--text-600);
}

.btn-yellow, .btn-orange {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-yellow:disabled, .btn-orange:disabled {
  cursor: not-allowed;
}

.btn-yellow, .btn-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}

.btn-yellow:active, .btn-orange:active {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.btn-yellow:hover:enabled, .btn-orange:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}

.btn-yellow:disabled, .btn-orange:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity));
  color: var(--text-600);
}

.btn-clear {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-clear:disabled {
  cursor: not-allowed;
}

.btn-clear {
  border-color: var(--bg-500);
  background-color: #0000;
}

.btn-clear:hover:enabled {
  background-color: var(--bg-600);
}

.btn-input {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-input:disabled {
  cursor: not-allowed;
}

.btn-input {
  background-color: #0000;
  border-width: 0;
}

.btn-input:hover {
  background-color: #0000;
}

.btn-bordered {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-bordered:disabled {
  cursor: not-allowed;
}

.btn-bordered {
  border-width: 1px;
  border-color: var(--bg-700);
  background-color: #0000;
}

.btn-bordered:hover {
  background-color: var(--bg-700);
}

.btn-icon {
  border-radius: .375rem;
  gap: .25rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.btn-icon:disabled {
  cursor: not-allowed;
}

.btn-icon {
  color: var(--text-500);
  background-color: #0000;
}

.btn-icon:hover, .btn-icon:focus {
  color: var(--text-900);
}

.focusable-card {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-bg-opacity: 1;
  background-color: rgb(13 16 23 / var(--tw-bg-opacity));
  border-radius: .375rem;
  max-width: 10rem;
  max-height: 24rem;
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focusable-card:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.focusable-card:active {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.tooltip {
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%222%22 fill=%22%23fff%22/%3E%3C/svg%3E");
}

option:checked {
  color: var(--text-900);
  background-color: var(--hl-400);
}

input[type="file"]::file-selector-button {
  color: var(--text-900);
  background-color: var(--hl-700);
  border: 0;
  padding: .5rem;
  transition: all 1s;
}

input[type="text"]:disabled, input[type="number"]:disabled {
  background-color: var(--bg-700);
  color: var(--text-600);
}

input[type="range"] {
  background: var(--bg-700);
  background-image: linear-gradient(var(--hl-700), var(--hl-700));
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  background: var(--hl-600);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 2px #555;
}

input[type="range"]:disabled {
  background-image: linear-gradient(var(--hl-900), var(--hl-900));
}

input[type="range"]::-webkit-slider-runnable-track {
  box-shadow: none;
  background: none;
  border: none;
}

input[type="text"]:disabled {
  background-color: var(--bg-900);
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.-top-4 {
  top: -1rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-1\/2 {
  bottom: 50%;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-\[-0\.5rem\] {
  bottom: -.5rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: .5rem;
}

.left-\[0px\] {
  left: 0;
}

.left-\[5\%\] {
  left: 5%;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-4 {
  right: 1rem;
}

.right-\[-0\.5rem\] {
  right: -.5rem;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[5\%\] {
  right: 5%;
}

.right-\[9px\] {
  right: 9px;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-4 {
  top: 1rem;
}

.top-\[6px\] {
  top: 6px;
}

.top-\[8px\] {
  top: 8px;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1\] {
  z-index: 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-24 {
  margin-left: 6rem;
}

.ml-32 {
  margin-left: 8rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[-32px\] {
  margin-left: -32px;
}

.ml-\[3px\] {
  margin-left: 3px;
}

.ml-\[5px\] {
  margin-left: 5px;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-\[3px\] {
  margin-right: 3px;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-0\.125rem\] {
  margin-top: -.125rem;
}

.mt-\[-195px\] {
  margin-top: -195px;
}

.mt-\[-224px\] {
  margin-top: -224px;
}

.mt-\[2px\] {
  margin-top: 2px;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3\/4 {
  height: 75%;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[128px\] {
  height: 128px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[28rem\] {
  height: 28rem;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[3rem\] {
  height: 3rem;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[40x\] {
  height: 40x;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[80vh\] {
  height: 80vh;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.max-h-10 {
  max-height: 2.5rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-\[120px\] {
  max-height: 120px;
}

.max-h-\[3rem\] {
  max-height: 3rem;
}

.max-h-\[400px\] {
  max-height: 400px;
}

.max-h-\[42px\] {
  max-height: 42px;
}

.max-h-\[50vh\] {
  max-height: 50vh;
}

.max-h-\[80vh\] {
  max-height: 80vh;
}

.max-h-\[90\%\] {
  max-height: 90%;
}

.max-h-\[calc\(80vh-132px\)\] {
  max-height: calc(80vh - 132px);
}

.max-h-full {
  max-height: 100%;
}

.max-h-max {
  max-height: max-content;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[12px\] {
  min-height: 12px;
}

.min-h-\[12rem\] {
  min-height: 12rem;
}

.min-h-\[2\.25rem\] {
  min-height: 2.25rem;
}

.min-h-\[2\.5rem\] {
  min-height: 2.5rem;
}

.min-h-\[300px\] {
  min-height: 300px;
}

.min-h-\[3rem\] {
  min-height: 3rem;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[40px\] {
  min-height: 40px;
}

.min-h-\[42px\] {
  min-height: 42px;
}

.min-h-\[455px\] {
  min-height: 455px;
}

.min-h-\[4rem\] {
  min-height: 4rem;
}

.min-h-\[50vh\] {
  min-height: 50vh;
}

.min-h-\[64px\] {
  min-height: 64px;
}

.min-h-\[6rem\] {
  min-height: 6rem;
}

.min-h-\[80px\] {
  min-height: 80px;
}

.min-h-\[8rem\] {
  min-height: 8rem;
}

.min-h-\[calc\(80vh-132px\)\] {
  min-height: calc(80vh - 132px);
}

.min-h-full {
  min-height: 100%;
}

.w-0 {
  width: 0;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-96 {
  width: 24rem;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[480px\] {
  width: 480px;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[calc\(100\%-24px\)\] {
  width: calc(100% - 24px);
}

.w-\[calc\(100\%-30px\)\] {
  width: calc(100% - 30px);
}

.w-\[calc\(100\%-5rem\)\] {
  width: calc(100% - 5rem);
}

.w-\[calc\(100\%-6rem\)\] {
  width: calc(100% - 6rem);
}

.w-\[calc\(100\%-8rem\)\] {
  width: calc(100% - 8rem);
}

.w-\[calc\(100vw-16px\)\] {
  width: calc(100vw - 16px);
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[12px\] {
  min-width: 12px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[24px\] {
  min-width: 24px;
}

.min-w-\[3rem\] {
  min-width: 3rem;
}

.min-w-fit {
  min-width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-\[160px\] {
  max-width: 160px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[20rem\] {
  max-width: 20rem;
}

.max-w-\[3rem\] {
  max-width: 3rem;
}

.max-w-\[50vw\] {
  max-width: 50vw;
}

.max-w-\[80\%\] {
  max-width: 80%;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-\[80px\] {
  max-width: 80px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-\[calc\(100\%-64px\)\] {
  max-width: calc(100% - 64px);
}

.max-w-\[unset\] {
  max-width: unset;
}

.max-w-full {
  max-width: 100%;
}

.max-w-none {
  max-width: none;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.table-auto {
  table-layout: auto;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-2 {
  --tw-border-spacing-x: .5rem;
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.-translate-x-1\/4 {
  --tw-translate-x: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/4 {
  --tw-translate-x: 25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-default {
  cursor: default;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-grab {
  cursor: grab;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.\!resize {
  resize: both !important;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.columns-3 {
  columns: 3;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-\[repeat\(auto-fit\,minmax\(105px\,1fr\)\)\] {
  grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
}

.grid-cols-\[repeat\(auto-fit\,minmax\(160px\,1fr\)\)\] {
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-\[2px\] {
  gap: 2px;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-\[10px\] {
  border-width: 10px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l, .border-l-\[1px\] {
  border-left-width: 1px;
}

.border-r-\[1px\] {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-\[var\(--bg-300\)\] {
  border-color: var(--bg-300);
}

.border-\[var\(--bg-400\)\] {
  border-color: var(--bg-400);
}

.border-\[var\(--bg-500\)\] {
  border-color: var(--bg-500);
}

.border-\[var\(--bg-600\)\] {
  border-color: var(--bg-600);
}

.border-\[var\(--bg-700\)\] {
  border-color: var(--bg-700);
}

.border-\[var\(--bg-800\)\] {
  border-color: var(--bg-800);
}

.border-\[var\(--bg-900\)\] {
  border-color: var(--bg-900);
}

.border-\[var\(--hl-500\)\] {
  border-color: var(--hl-500);
}

.border-\[var\(--hl-700\)\] {
  border-color: var(--hl-700);
}

.border-\[var\(--hl-800\)\] {
  border-color: var(--hl-800);
}

.border-\[var\(--hl-900\)\] {
  border-color: var(--hl-900);
}

.border-\[var\(--red-600\)\] {
  border-color: var(--red-600);
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-white\/10 {
  border-color: #ffffff1a;
}

.border-white\/5 {
  border-color: #ffffff0d;
}

.bg-\[\#1f4439\] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 68 57 / var(--tw-bg-opacity));
}

.bg-\[\#55b89c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(85 184 156 / var(--tw-bg-opacity));
}

.bg-\[var\(--bg-600\)\] {
  background-color: var(--bg-600);
}

.bg-\[var\(--bg-700\)\] {
  background-color: var(--bg-700);
}

.bg-\[var\(--bg-800\)\] {
  background-color: var(--bg-800);
}

.bg-\[var\(--bg-900\)\] {
  background-color: var(--bg-900);
}

.bg-\[var\(--black-700\)\] {
  background-color: var(--black-700);
}

.bg-\[var\(--hl-700\)\] {
  background-color: var(--hl-700);
}

.bg-\[var\(--hl-800\)\] {
  background-color: var(--hl-800);
}

.bg-\[var\(--hl-900\)\] {
  background-color: var(--hl-900);
}

.bg-\[var\(--text-200\)\] {
  background-color: var(--text-200);
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-red-500\/10 {
  background-color: #ef44441a;
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-red-600\/10 {
  background-color: #dc26261a;
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/0 {
  background-color: #fff0;
}

.bg-white\/10 {
  background-color: #ffffff1a;
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[var\(--bg-800\)\] {
  --tw-gradient-from: var(--bg-800) var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-slate-700 {
  --tw-gradient-to: #33415500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #334155 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-\[var\(--bg-800\)\] {
  --tw-gradient-to: var(--bg-800) var(--tw-gradient-to-position);
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-\[var\(--text-900\)\] {
  fill: var(--text-900);
}

.fill-cyan-300 {
  fill: #67e8f9;
}

.fill-emerald-400 {
  fill: #34d399;
}

.fill-red-500 {
  fill: #ef4444;
}

.fill-red-600 {
  fill: #dc2626;
}

.fill-red-800 {
  fill: #991b1b;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[2px\] {
  padding: 2px;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[2px\] {
  padding-left: 2px;
  padding-right: 2px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[0\.5rem\] {
  font-size: .5rem;
}

.text-\[0\.6rem\] {
  font-size: .6rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[1em\] {
  font-size: 1em;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-\[16\] {
  color: 16;
}

.text-\[var\(--coolgray-800\)\] {
  color: var(--coolgray-800);
}

.text-\[var\(--green-600\)\] {
  color: var(--green-600);
}

.text-\[var\(--hl-200\)\] {
  color: var(--hl-200);
}

.text-\[var\(--hl-300\)\] {
  color: var(--hl-300);
}

.text-\[var\(--hl-400\)\] {
  color: var(--hl-400);
}

.text-\[var\(--hl-500\)\] {
  color: var(--hl-500);
}

.text-\[var\(--hl-700\)\] {
  color: var(--hl-700);
}

.text-\[var\(--text-500\)\] {
  color: var(--text-500);
}

.text-\[var\(--text-600\)\] {
  color: var(--text-600);
}

.text-\[var\(--text-700\)\] {
  color: var(--text-700);
}

.text-\[var\(--text-800\)\] {
  color: var(--text-800);
}

.text-\[var\(--text-900\)\] {
  color: var(--text-900);
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/25 {
  color: #ffffff40;
}

.text-white\/50 {
  color: #ffffff80;
}

.text-white\/75 {
  color: #ffffffbf;
}

.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgb(254 249 195 / var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity));
}

.text-opacity-0 {
  --tw-text-opacity: 0;
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.underline {
  text-decoration-line: underline;
}

.decoration-dotted {
  text-decoration-style: dotted;
}

.accent-\[var\(--hl-400\)\] {
  accent-color: var(--hl-400);
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black {
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-900 {
  --tw-shadow-color: #134e4a;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-md {
  --tw-blur: blur(12px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-0 {
  --tw-saturate: saturate(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.text-shadow {
  text-shadow: 0 2px 4px var(--tw-shadow-color);
}

.text-shadow-lg {
  text-shadow: 0 8px 16px var(--tw-shadow-color);
}

:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

* > code {
  background-color: var(--bg-600);
  color: var(--text-900);
  border-radius: .375rem;
  padding: .25rem;
}

body {
  color: var(--text-100);
  font-family: var(--sitewide-font);
}

.w-10 {
  width: 2.5rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-8xl {
  max-width: 88rem;
}

body {
  color: var(--text-900);
  background-color: var(--bg-900);
  height: -webkit-fill-available;
}

@supports (-webkit-touch-callout: none) {
  .app {
    height: calc(100vh - 74px);
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
}

.link {
  cursor: pointer;
  color: var(--hl-500);
}

.link:hover {
  color: var(--hl-300);
}

.base-link {
  cursor: pointer;
}

.base-link:hover {
  color: var(--hl-300);
}

.highlight {
  color: var(--hl-400);
}

.text-hl-100 {
  color: var(--hl-900);
}

.text-hl-200 {
  color: var(--hl-800);
}

.text-hl-300 {
  color: var(--hl-700);
}

.text-hl-400 {
  color: var(--hl-600);
}

.text-hl-500 {
  color: var(--hl-500);
}

.text-hl-600 {
  color: var(--hl-400);
}

.text-hl-700 {
  color: var(--hl-300);
}

.text-hl-800 {
  color: var(--hl-200);
}

.text-hl-900 {
  color: var(--hl-100);
}

textarea::placeholder, textarea:placeholder-shown {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

textarea:disabled {
  background-color: var(--bg-900);
}

.markdown code {
  font-size: .75rem !important;
}

.markdown a {
  cursor: pointer;
  color: var(--hl-500);
}

.markdown a:hover {
  color: var(--hl-300);
}

.markdown h1 {
  color: var(--hl-400);
  padding-top: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.markdown h2 {
  color: var(--hl-400);
  padding-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.markdown h3 {
  color: var(--hl-400);
  padding-top: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.markdown h4 {
  color: var(--hl-400);
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.markdown p {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.markdown em {
  color: var(--hl-200);
  font-weight: 400;
}

.markdown ul {
  padding-left: 1.25rem;
  list-style-type: disc;
  list-style-position: inside;
}

.markdown ol {
  counter-reset: item;
  padding-left: 1.25rem;
  list-style-position: inside;
}

.markdown ol > li {
  counter-increment: item;
}

.markdown ol > li:before {
  content: counters(item, ".") ". ";
}

.markdown > ul > li > ul {
  padding-left: 1.25rem;
  list-style-type: circle;
  list-style-position: inside;
}

.markdown > blockquote {
  border-left: .25em solid var(--bg-300);
  padding-left: 1rem;
}

.markdown ul > li {
  list-style-type: disc;
}

.markdown ul > li > ul > li {
  list-style-type: circle;
}

.markdown ul > li > ul > li > ul > li {
  list-style-type: square;
}

.overlay {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  inset: 0;
}

.hover\:bg-600:hover {
  background-color: var(--bg-600);
}

.hover\:bg-700:hover {
  background-color: var(--bg-700);
}

.hover\:text-900:hover {
  color: var(--text-900);
}

.first-letter\:capitalize:first-letter {
  text-transform: capitalize;
}

.checked\:border-\[var\(--hl-500\)\]:checked {
  border-color: var(--hl-500);
}

.checked\:bg-\[var\(--hl-600\)\]:checked {
  background-color: var(--hl-600);
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-\[var\(--bg-500\)\]:hover {
  border-color: var(--bg-500);
}

.hover\:border-\[var\(--bg-600\)\]:hover {
  border-color: var(--bg-600);
}

.hover\:border-\[var\(--hl-400\)\]:hover {
  border-color: var(--hl-400);
}

.hover\:border-white\/20:hover {
  border-color: #fff3;
}

.hover\:bg-\[var\(--bg-600\)\]:hover {
  background-color: var(--bg-600);
}

.hover\:bg-\[var\(--bg-700\)\]:hover {
  background-color: var(--bg-700);
}

.hover\:bg-\[var\(--bg-800\)\]:hover {
  background-color: var(--bg-800);
}

.hover\:text-\[var\(--text-800\)\]:hover {
  color: var(--text-800);
}

.hover\:text-\[var\(--text-900\)\]:hover {
  color: var(--text-900);
}

.hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:bg-\[var\(--bg-800\)\]:active {
  background-color: var(--bg-800);
}

.disabled\:opacity-10:disabled {
  opacity: .1;
}

.disabled\:opacity-60:disabled {
  opacity: .6;
}

.disabled\:hover\:scale-100:hover:disabled {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:bg-white\/5 {
  background-color: #ffffff0d;
}

.group:active .group-active\:bg-white\/10 {
  background-color: #ffffff1a;
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
}

@media not all and (width >= 1280px) {
  .max-sm\:p-1 {
    padding: .25rem;
  }
}

@media (width >= 768px) {
  .md\:left-\[10\%\] {
    left: 10%;
  }

  .md\:right-\[10\%\] {
    right: 10%;
  }

  .md\:-mt-20 {
    margin-top: -5rem;
  }

  .md\:h-16 {
    height: 4rem;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-\[26rem\] {
    width: 26rem;
  }

  .md\:border-\[20px\] {
    border-width: 20px;
  }

  .md\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xs\:mr-auto {
    margin-right: auto;
  }
}

@media (width >= 1024px) {
  .lg\:right-\[calc\(14\%-22\.5rem\)\] {
    right: calc(14% - 22.5rem);
  }
}

@media (width >= 1280px) {
  .sm\:inset-0 {
    inset: 0;
  }

  .sm\:bottom-10 {
    bottom: 2.5rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-\[302px\] {
    margin-left: 302px;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-3\/4 {
    height: 75%;
  }

  .sm\:h-\[42rem\] {
    height: 42rem;
  }

  .sm\:h-\[550px\] {
    height: 550px;
  }

  .sm\:max-h-\[550px\] {
    max-height: 550px;
  }

  .sm\:max-h-\[90vh\] {
    max-height: 90vh;
  }

  .sm\:max-h-\[calc\(90vh-132px\)\] {
    max-height: calc(90vh - 132px);
  }

  .sm\:min-h-\[2\.5rem\] {
    min-height: 2.5rem;
  }

  .sm\:min-h-\[calc\(90vh-132px\)\] {
    min-height: calc(90vh - 132px);
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-11\/12 {
    width: 91.6667%;
  }

  .sm\:w-24 {
    width: 6rem;
  }

  .sm\:w-3\/12 {
    width: 25%;
  }

  .sm\:w-9\/12 {
    width: 75%;
  }

  .sm\:w-\[calc\(100vw-64px\)\] {
    width: calc(100vw - 64px);
  }

  .sm\:w-\[calc\(50vw\)\] {
    width: 50vw;
  }

  .sm\:w-fit {
    width: fit-content;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-\[280px\] {
    max-width: 280px;
  }

  .sm\:max-w-\[30vw\] {
    max-width: 30vw;
  }

  .sm\:max-w-\[400px\] {
    max-width: 400px;
  }

  .sm\:max-w-\[550px\] {
    max-width: 550px;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:gap-1 {
    gap: .25rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:rounded-full {
    border-radius: 9999px;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:rounded-sm {
    border-radius: .125rem;
  }

  .sm\:object-cover {
    object-fit: cover;
  }

  .sm\:object-scale-down {
    object-fit: scale-down;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pr-3 {
    padding-right: .75rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-\[12px\] {
    font-size: 12px;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:gap-x-8 {
    column-gap: 2rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:w-\[600px\] {
    width: 600px;
  }
}
/*# sourceMappingURL=index.86f1e892.css.map */
